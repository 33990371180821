import { SET_PRICE_RANGE } from "../constants/priceRangeConstants";

const initialState = {
    priceRange: [600, 5000],
  };
  
  const priceReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PRICE_RANGE:
        return { ...state, priceRange: action.payload };
      default:
        return state;
    }
  };

  export default priceReducer;
  