import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const About = () => {
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="About"/>
      <div className="pb-10">
        <section class="">
          <div class="py-12 bg-white">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

              <div class="lg:text-center">
                <h2
                  class="font-heading mb-4 bg-orange-100 text-orange-800 px-4 py-2 rounded-lg md:w-64 md:mx-auto text-xs font-semibold tracking-widest text-black uppercase title-font">
                  Why Choose Kamandora?
                </h2>
                <p class="font-heading mt-2 text-3xl leading-8 font-semibold tracking-tight text-gray-900 sm:text-4xl">
                  Expertly Crafted Bags for Every Occasion
                </p>
                <p class="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                  At Kamandora, we believe in combining style, functionality, and durability in every bag we offer. Whether you're traveling, commuting, or just need a reliable everyday bag, we have you covered.
                </p>
                <h1 className="max-w-[600px] text-base text-lightText mb-2mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                  <span className="text-primeColor font-semibold text-lg">Kamandora</span>{" "}
                  is a leading ecommerce brand that is recognized for celebrating 
                  the essence of classic Worldwide cool looking style.
                </h1>
              </div>

              <div class="mt-10">
                <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                  <div class="relative">
                    <dt>
                      <div
                        class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                        <img src="https://www.svgrepo.com/show/503163/api-settings.svg" alt="icon"/>
                      </div>
                      <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Premium Materials</p>
                    </dt>
                    <dd class="mt-2 ml-16 text-base text-gray-500">
                      Our bags are crafted from high-quality materials, ensuring they stand the test of time and serve you well on every journey.
                    </dd>
                  </div>
                  <div class="relative">
                    <dt>
                      <div
                        class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                        <img src="https://www.svgrepo.com/show/503138/webpack.svg" alt="icon"/>
                      </div>
                      <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Versatile Designs</p>
                    </dt>
                    <dd class="mt-2 ml-16 text-base text-gray-500">
                      From sleek laptop bags to spacious travel bags, our designs cater to various needs and preferences, ensuring you find the perfect match.
                    </dd>
                  </div>
                  <div class="relative">
                    <dt>
                      <div
                        class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                        <img src="https://www.svgrepo.com/show/511771/dashboard-671.svg" alt="icon"/>

                      </div>
                      <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Affordable Prices</p>
                    </dt>
                    <dd class="mt-2 ml-16 text-base text-gray-500">
                      We believe in offering top-quality bags at prices that won't break the bank, making luxury accessible to everyone.
                    </dd>
                  </div>
                  <div class="relative">
                    <dt>
                      <div
                        class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                        <img src="https://www.svgrepo.com/show/76267/free-commercial-label.svg" alt="icon"/>

                      </div>
                      <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Customer Satisfaction</p>
                    </dt>
                    <dd class="mt-2 ml-16 text-base text-gray-500">
                      Your happiness is our priority. Our dedicated support team is always here to ensure you have the best shopping experience.
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </section>

        <Link to="/shop">
          <button className="w-52 h-10 bg-[#af8965] rounded-full text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
