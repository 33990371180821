import React from "react";

import {
  saleImgOne
} from "../../../assets/images/index";
import Image from "../../designLayouts/Image";
import ShopNow from "../../designLayouts/buttons/ShopNow";

const Sale = () => {
  return (
    <div className="py-10 flex flex-col md:flex-row items-center justify-between gap-4 lg:gap-10">
      <div className="bg-[#ede4dd] w-full md:w-full lg:w-full h-full flex flex-row justify-center items-center text-black">
        <div className="aspect-w-3 aspect-h-2 w-full mb-4">
          <Image className="h-full w-full object-cover" imgSrc={saleImgOne} />
        </div>
        <div className="text-left h-140 md:h-200 lg:h-260 w-full mx-3 ">
          <div className="mx-4">
            <h2 class="md:text-5xl lg:text-5xl font-bold mb-3 mt-2">Your Perfect Travel Companion</h2>
            <p class="md:text-4xl lg:text-4xl mb-6">Elevate your journey with bags crafted for comfort and convenience.</p>
            <div className=" mb-8">
              <ShopNow />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sale;
