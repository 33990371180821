import React from "react";

const BannerBottom = () => {
  return (
    <div className="w-full bg-white px-4">
      
    </div>
  );
};

export default BannerBottom;
