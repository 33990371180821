import React, { Fragment, useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import { FaSearch } from "react-icons/fa";
import MetaData from '../Layout/MetaData';
import Loader from '../Layout/Loader';
import Sidebar from './Sidebar';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getProductReviews, deleteReview, clearErrors } from '../../redux/actions/productAction';
import { DELETE_REVIEW_RESET } from '../../redux/constants/productConstants';

const ProductReviews = () => {

    const [productId, setProductId] = useState('');

    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products)
    const { error, reviews } = useSelector(state => state.productReviews);
    const { isDeleted, error: deleteError } = useSelector(state => state.review);

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);


    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const filtered = products.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredProducts(filtered);
    }, [searchQuery]);

    useEffect(() => {

        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }

        if (deleteError) {
            toast.error(deleteError);
            dispatch(clearErrors());
        }

        if (productId !== '') {
            dispatch(getProductReviews(productId));
        }

        if (isDeleted) {
            toast.success('Review deleted successfully');
            dispatch({ type: DELETE_REVIEW_RESET });
        }

    }, [dispatch, error, productId, isDeleted, deleteError]);

    const deleteReviewHandler = (id) => {
        dispatch(deleteReview(id, productId));
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(getProductReviews(productId));
    };

    const setReviews = () => {
        const data = {
            columns: [
                {
                    label: 'Review ID',
                    field: 'id',
                    sort: 'asc'
                },
                {
                    label: 'Rating',
                    field: 'rating',
                    sort: 'asc'
                },
                {
                    label: 'Comment',
                    field: 'comment',
                    sort: 'asc'
                },
                {
                    label: 'User',
                    field: 'user',
                    sort: 'asc'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                },
            ],
            rows: []
        };

        reviews.forEach(review => {
            data.rows.push({
                id: review.id,
                rating: review.rating,
                comment: review.comment,
                user: review.name,

                actions: (
                    <button
                        className="text-white bg-red-600 hover:bg-red-700 py-1 px-2 ml-2 rounded"
                        onClick={() => deleteReviewHandler(review.id)}
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                )
            });
        });

        return data;
    };

    return (
        <Fragment>
            <MetaData title={'Product Reviews'} />
            <div className="flex flex-row ml-3">
                <div className="w-1/4">
                    <Sidebar />
                </div>

                <div className="w-3/4 ml-3">
                    <Fragment>
                        <div className="flex justify-center mt-5">
                            <div className="w-1/2">
                                <form onSubmit={submitHandler}>
                                    <div className="relative w-full lg:w-[600px] h-[50px] text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl border border-[#ede4dd]">
                                        <input
                                            className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
                                            type="text"
                                            onChange={handleSearch}
                                            value={searchQuery}
                                            placeholder="Search your products here"
                                        />
                                        <FaSearch className="w-5 h-5" color="#af8965" />
                                        {searchQuery && (
                                            <div
                                                className="w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer"
                                            >
                                                {filteredProducts.map((item) => (
                                                    <div
                                                        onClick={() => { setProductId(item.id); setSearchQuery(""); }}
                                                        key={item.id}
                                                        className="max-w-[600px] h-28 bg-[#ede4dd] mb-3 flex items-center gap-3"
                                                    >
                                                        <img className="w-24" src={JSON.parse(item.images)[0].url} alt="productImg" />
                                                        <div className="flex flex-col gap-1">
                                                            <p className="font-semibold text-lg">{item.name}</p>
                                                            <p className="text-xs">
                                                                {item.description.length > 100 ? `${item.description.slice(0, 100)}...` : item.description}
                                                            </p>
                                                            <p className="text-sm">
                                                                Price: <span className="text-primeColor font-semibold">Ksh. {item.price}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>

                        {reviews && reviews.length > 0 ? (
                            <MDBDataTable
                                data={setReviews()}
                                className="custom-mdb-table p-3"
                                bordered
                                striped
                                hover
                                paginationLabel={['Previous', 'Next']}
                                entriesLabel="Show Entries"
                                infoLabel={['Showing', 'to', 'of', 'entries']}
                                searchLabel="Search"
                                pagesAmount={4}
                                responsive
                            />
                        ) : (
                            <p className="mt-5 text-center text-gray-600">No Reviews.</p>
                        )}

                    </Fragment>
                </div>
            </div>

        </Fragment>
    );
};

export default ProductReviews;
