import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPriceRange } from "../../../../redux/actions/priceActions";
import NavTitle from "./NavTitle";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const Price = () => {
  const dispatch = useDispatch();
  const price = useSelector((state) => state.priceValues.priceRange);

  const handlePriceChange = (price) => {
    dispatch(setPriceRange(price));
  };

  return (
    <div className="cursor-pointer">
      <NavTitle title="Shop by Price" icons={false} />
      <div className="font-titleFont">
        <Range
          className="mt-5"
          marks={{
            600: `Ksh. 600`,
            5000: `Ksh. 5000`,
          }}
          min={600}
          max={5000}
          defaultValue={[600, 5000]}
          tipFormatter={(value) => `Ksh.${value}`}
          tipProps={{
            placement: "top",
            visible: true,
          }}
          value={price}
          onChange={handlePriceChange}
        />
      </div>
    </div>
  );
};

export default Price;
